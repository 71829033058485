import React, { Component } from "react";

import HeaderLinkUP from "../HeaderSection/Header-linkup";
import FooterSection from "../FooterSection/Footer-linkup";

class PrivacyPolicy extends Component {
  state = {
    data: [
      {
        id: 1,
        heading: "Main pages",
        thumb: "/img/instruction_display_main.png",
        serviceData: [
          {
            id: 1,
            text: "Press the display button to switch pages",
          },
          {
            id: 5,
            text: "E: (E)co Mode, B: (B)luetooth Connection",
          },
          {
            id: 2,
            text: "Page1: Input Voltage, Output Watts",
          },
          {
            id: 3,
            text: "Page2: Output Hz, Output Voltage Type",
          },
          {
            id: 4,
            text: "Page3: Internal Temperature, Countdown Timer",
          },
        ],
      },
      {
        id: 2,
        heading: "Setting Pages",
        thumb: "/img/instruction_display_settings.png",
        serviceData: [
          {
            id: 1,
            text: "On the main page, press and hold the display button for 5 seconds to switch to the settings page.",
          },
          {
            id: 2,
            text: "On the settings page, click the display button to pick which settings to be changed.",
          },
          {
            id: 3,
            text: "Once the setting is picked, press and hold the display button to change the setting to different values.",
          },
          {
            id: 4,
            text: "To go back to the main page, press and hold the display button on the EXIT page.",
          },
        ],
      },
      {
        id: 3,
        heading: "Dynamic Pages",
        thumb: "/img/instruction_display_dynamic.png",
        serviceData: [
          {
            id: 1,
            text: "When protection events occurred, the display will automatically switch to the protection page until the issue is resolved.",
          },
          {
            id: 2,
            text: "When the issue is resolved, please restart the inverter to go back to the main page.",
          },
        ],
      },
      {
        id: 3,
        heading: "Charging Page",
        thumb: "/img/instruction_display_charging.png",
        serviceData: [
          {
            id: 1,
            text: "For Inverter Charger models, the main page will switch to the charging page as soon as the AC input is connected. The display will indicate the following info:",
          },
          {
            id: 2,
            text: "AC Output: BYPS(Bypassing) or OFF",
          },
          {
            id: 3,
            text: "Charging State: BLK(Bulk), ABS(Absorption), FLT(Float)",
          },
          {
            id: 3,
            text: "Battery Voltage and B: (B)luetooth Connection",
          },
        ],
      },
    ],
  };
  render() {
    return (
      <div className="homepage-2">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <HeaderLinkUP imageData={"/img/logo-white.png"} />
          {/* <Breadcrumb title="Privacy Policy" /> */}
          <section
            className="section breadcrumb-area overlay-dark d-flex align-items-center"
            style={{ height: 90 }}
          ></section>

          <section className="section subscribe-area ptb_100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7">
                  <div className="subscribe-content text-center">
                    <h2>Smart Display Instruction</h2>
                    <p className="my-4">
                      {"How to VIEW & CONFIGURE settings thru display button"}
                    </p>
                    {/* Subscribe Form */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {this.state.data.map((itemData, idx) => {
            return (
              <section className="section service-area overflow-hidden ptb_50">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                      {/* Service Text */}
                      <div className="service-text pt-5 pt-lg-0 px-0 px-lg-4">
                        <h2 className="text-capitalize mb-4">
                          {itemData.heading}
                        </h2>
                        {/* Service List */}
                        <ul className="service-list style-two">
                          {/* Single Service */}
                          {itemData.serviceData.map((item, idx) => {
                            return (
                              <li
                                key={`sf_${idx}`}
                                className="single-service py-2"
                              >
                                <div className="service-text">
                                  <p>{item.text}</p>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-6 order-1 order-lg-2">
                      {/* Service Thumb */}
                      <div className="service-thumb mx-auto">
                        <img src={itemData.thumb} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}

          <FooterSection />
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
