import React, { Component } from "react";

const DATA = {
  heading: "Have questions?",
  headingText: "",
  faqText: "Haven't find suitable answer?",
  faqTextLink: "Tell us what you need.",
};

const FAQ_DATA = [
  // {
  //   id: 1,
  //   title: "How to install LinkUP?",
  //   content:
  //     "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
  // },
  {
    id: 2,
    title: "Is LinkUP free?",
    content:
      "Yes, LinkUP is 100% FREE, no in-app purchases, no subscription, no Ads, no nothing!",
  },
  {
    id: 1,
    title: "Can I configure the power inverter settings without LinkUP app?",
    content:
      "Yes, you can configure settings thru the display button on all the LinkUP compatible inverters. For detail smart display instruction, please visit: ",
  },
  {
    id: 3,
    title: "Can I try LinkUP without a physical bluetooth power inverter?",
    content:
      "Yes, you can still experience the features by checking the virtual inverters in the DEMO Mode.",
  },
  {
    id: 5,
    title: "Can LinkUP work in background mode?",
    content:
      "Yes, once the connection is established, even if the app is in background, it will still be receiving data such as notifications of timer, protections and other events from the power inverter.",
  },
  {
    id: 4,
    title: "Can't find any power inverter after scanning?",
    content:
      "Make sure your Bluetooth is turned on and the power inverter is within the connection range. Refresh a few more times to see if the power inverters show up again. Alternatively, restart both the app and the power inverter and try scanning again.",
  },
];

class FaqSection extends Component {
  state = {
    data: {},
    faqData: [],
  };
  componentDidMount() {
    this.setState({
      data: DATA,
      faqData: FAQ_DATA,
    });
  }
  render() {
    return (
      <section id="faq" className="section faq-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-7">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{this.state.data.heading}</h2>
                {/* <p className="d-none d-sm-block mt-4">
                  {this.state.data.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.data.headingText}
                </p> */}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              {/* FAQ Content */}
              <div className="faq-content">
                {/* sApp Accordion */}
                <div className="accordion" id="sApp-accordion">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                      {/* Single Accordion Item */}
                      {this.state.faqData.map((item, idx) => {
                        return (
                          <div
                            key={`ft_${idx}`}
                            className="card border-top-0 border-left-0 border-right-0 border-bottom"
                          >
                            {/* Card Header */}
                            <div className="card-header bg-inherit border-0 p-0">
                              <h2 className="mb-0">
                                <button
                                  className="btn px-0 py-3"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                >
                                  {item.title}
                                </button>
                              </h2>
                            </div>
                            <div
                              id="collapseOne"
                              className="collapse"
                              data-parent="#sApp-accordion"
                            >
                              {/* Card Body */}
                              <div className="card-body px-0 py-3">
                                {item.content}
                                {item.content.includes(
                                  "smart display instruction"
                                ) && (
                                  <a href={"/instruction"}>
                                    <bold>
                                      {"【Smart Display Instruction】"}
                                    </bold>
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FaqSection;
