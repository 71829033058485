import React, { Component } from "react";

const initData = {
  heading: "Download Now",
  headingText:
    "LinkUP is available now in select contries for both iOS and android devices.",
  headingTexttwo:
    "LinkUP is available now in select contries for both iOS and android devices.",
  headingSlug: "* Support iOS 11.0+ and Android 6+ devices with Bluetooth 4.0+",
  playImg: "/img/google-play.png",
  appleImg: "/img/app-store.png",
};

class Download extends Component {
  state = {
    initData: {},
  };
  componentDidMount() {
    this.setState({
      initData: initData,
    });
  }
  render() {
    return (
      <section
        id="download"
        className="section download-area overlay-dark ptb_150"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              {/* Download Text */}
              <div className="download-text text-center">
                <h2 className="text-white">{this.state.initData.heading}</h2>
                <p className="text-white my-3 d-none d-sm-block">
                  {this.state.initData.headingText}
                </p>
                <p className="text-white my-3 d-block d-sm-none">
                  {this.state.initData.headingTexttwo}
                </p>
                {/* Store Buttons */}
                <div className="button-group store-buttons d-flex justify-content-center">
                  <a href="/android">
                    <img
                      src={this.state.initData.playImg}
                      alt=""
                      style={{ height: 60 }}
                    />
                  </a>
                  <a href="/ios">
                    <img
                      src={this.state.initData.appleImg}
                      alt=""
                      style={{ height: 60, paddingLeft: 8 }}
                    />
                  </a>
                </div>
                <span className="d-inline-block text-white fw-3 font-italic mt-3">
                  {this.state.initData.headingSlug}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Download;
