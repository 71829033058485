import React, { Component } from "react";

const DATA = {
  heading: "Features",
  headingText: "",
  headingTexttwo: "",
  featureThumb: "/img/features_thumb.png",
};

const FEATURE_DATA = [
  {
    id: 1,
    iconClass: "flaticon-tap",
    image: "/img/outline_leaderboard_black_48dp.png",
    title: "Real-time Status",
    text:
      "Once connect to the power inverter, user can view the status like: input volt, output watts, output volt type, sine wave type, output frequency, and more.",
  },
  {
    id: 2,
    iconClass: "flaticon-fingerprint",
    title: "Customizable Settings",
    image: "/img/outline_settings_black_48dp.png",
    text:
      "Various settings available: eco mode, cooling mode, buzzer on/off, output volt, protection pre-alarm, protection shutdown thresholds, and more.",
  },
  {
    id: 3,
    iconClass: "flaticon-notification",
    title: "OTA Firmware Update",
    image: "/img/outline_cloud_download_black_48dp.png",
    text:
      "The over-the-air update allows users to download the latest power inverter firmware thru the smartphones, making sure the power inverter is issue-free with up-to-date features.",
  },
  {
    id: 4,
    iconClass: "flaticon-place",
    title: "Instant Notification",
    image: "/img/outline_mark_chat_unread_black_48dp.png",
    text:
      "Get instant app notification of the power inverter events when connected, even if the app is in the background.",
  },
  {
    id: 5,
    iconClass: "flaticon-settings",
    title: "Bluetooth LE",
    image: "/img/outline_bluetooth_black_48dp.png",
    text:
      "The app uses Bluetooth LE technology for fast and efficient communication.",
  },
  {
    id: 6,
    iconClass: "flaticon-language",
    title: "Multiple Language",
    image: "/img/outline_language_black_48dp.png",
    text:
      "Support more than 10+ languages that fit into local market around the world.",
  },
];

class FeatureSection extends Component {
  state = {
    data: {},
    featureDataTwo: [],
  };
  componentDidMount() {
    this.setState({
      data: DATA,
      featureDataTwo: FEATURE_DATA,
    });
  }
  render() {
    return (
      <section id="features" className="section features-area ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2>{this.state.data.heading}</h2>
                {/* <p className="d-none d-sm-block mt-4">
                  {this.state.data.headingText}
                </p>
                <p className="d-block d-sm-none mt-4">
                  {this.state.data.headingTexttwo}
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.featureDataTwo.map((item, idx) => {
              return (
                <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                  {/* Icon Box */}
                  <div className="icon-box text-center p-4">
                    {/* Featured Icon */}
                    {/* <div className="featured-icon mb-3">
                      <span className={item.iconClass} />
                    </div> */}
                    {/* Featured Image */}
                    <div className="featured-img">
                      <img
                        src={item.image}
                        alt=""
                        width="20%"
                        style={{ padding: 8 }}
                      />
                    </div>
                    {/* Icon Text */}
                    <div className="icon-text">
                      <h3 className="mb-2">{item.title}</h3>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default FeatureSection;
