import React, { Component } from "react";

import HeaderLinkUP from "../HeaderSection/Header-linkup";
import FooterSection from "../FooterSection/Footer-linkup";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="homepage-2">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <HeaderLinkUP imageData={"/img/logo-white.png"} />
          {/* <Breadcrumb title="Privacy Policy" /> */}
          <section
            className="section breadcrumb-area overlay-dark d-flex align-items-center"
            style={{ height: 90 }}
          ></section>

          <section className="section subscribe-area ptb_100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7">
                  <div className="subscribe-content text-center">
                    <h2>Android Downloads</h2>
                    <p className="my-4">{"Support > Android Downloads"}</p>
                    <div className="text-center">
                      {" "}
                      {/* Added text-center class */}
                      <table
                        style={{
                          border: "2px solid black",
                          margin: "0 auto",
                          width: "80%",
                        }}
                      >
                        {" "}
                        {/* Added margin: "0 auto" to center the table */}
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid black" }}>
                              Release Date
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Version
                            </th>
                            <th style={{ border: "1px solid black" }}>
                              Download
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ border: "1px solid black" }}>
                              2024.03.29
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              1.0.9_release
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <a href={"/files/app-release_1.0.9.apk"}>
                                <bold>
                                  <u>{"Click to Install"}</u>
                                </bold>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid black" }}>
                              2023.08.07
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              1.0.8_release
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <a href={"/files/app-release_1.0.8.apk"}>
                                <bold>
                                  <u>{"Click to Install"}</u>
                                </bold>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "1px solid black" }}>
                              2023.05.29
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              1.0.7_release
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <a href={"/files/app-release_1.0.7.apk"}>
                                <bold>
                                  <u>{"Click to Install"}</u>
                                </bold>
                              </a>
                            </td>
                          </tr>
                          {/* Add more rows as needed */}
                        </tbody>
                      </table>
                    </div>
                    {/* Subscribe Form */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="contact" className="contact-area bg-gray ptb_100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-7">
                  <div>
                    <strong>Regarding Android Beta Builds:</strong>
                    <br></br>
                    <i>
                      Please note the above builds end with "_beta" version are
                      provided for testing purposes only. By downloading and
                      using these beta versions, you understand and agree to the
                      following:
                      <ul>
                        <li>
                          ■ The App is a work-in-progress and may have limited
                          functionality or contain errors.
                        </li>
                        <li>
                          ■ Your use of the beta version is voluntary and at
                          your own discretion.
                        </li>
                        <li>
                          ■ We appreciate your feedback and suggestions to help
                          us improve the App's performance and features.
                        </li>
                        <li>
                          ■ We may make changes or discontinue the beta version
                          at any time without prior notice.
                        </li>
                      </ul>
                      <br></br>
                      Thank you for your participation in the beta testing
                      program.
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
