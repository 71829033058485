import React, { Component } from "react";
import Header from "../HeaderSection/Header-linkup";

const DATA_IOS = {
  heading: "Thank you for downloading LinkUP",
  content:
    "You should automatically be transferred to App Store in a few seconds, but if not, click here.",
  btnText: "Download from App Store",
  href: "https://apps.apple.com/fj/app/linkup-power-management/id1532700010#?platform=iphone",
};

const DATA_ANDROID = {
  heading: "Thank you for downloading LinkUP",
  content:
    "You should automatically be transferred to Google Play Store in a few seconds, but if not, click here.",
  btnText: "Download from Google Play Store",
  href: "https://play.google.com/store/apps/details?id=com.linkchamp.linkup",
};
// const DATA_ANDROID = {
//   heading: "Thank you for downloading LinkUP",
//   content:
//     "You should automatically be transferred to the download page in a few seconds, but if not, click here.",
//   btnText: "Download",
//   href: "/beta",
// };

class ThankYou extends Component {
  state = {
    initData: [],
  };
  componentDidMount() {
    this.setState({
      initData: this.props.isAndroid ? DATA_ANDROID : DATA_IOS,
    });

    //Start the timer, open app store after 2 secs
    setTimeout(
      function () {
        window.open(
          this.props.isAndroid ? DATA_ANDROID.href : DATA_IOS.href,
          "_blank"
        );
      }.bind(this),
      2000
    );
  }
  render() {
    return (
      <div className="inner inner-pages">
        <div className="main">
          <Header imageData={"/img/logo-white.png"} />
          <section
            id="home"
            className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden"
          >
            <div className="container h-100">
              <div className="row align-items-center h-100">
                <div className="col-12 col-md-8">
                  <div className="welcome-intro">
                    <h1 className="text-white">
                      {this.state.initData.heading}
                    </h1>
                    <p className="text-white my-4">
                      {this.state.initData.content}
                    </p>
                    <a
                      href={this.state.initData.href}
                      className="btn sApp-btn text-uppercase"
                    >
                      {this.state.initData.btnText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ThankYou;
