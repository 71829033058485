import React, { Component } from "react";

const DATA = {
  image: "/img/app_icon.png",
  text: "",
  linkText_1: "Product",
  linkText_2: "Support",
  linkText_3: "Download",
  iconList: [
    {
      id: 1,
      link: "facebook",
      iconClass: "fab fa-facebook-f",
    },
    {
      id: 2,
      link: "twitter",
      iconClass: "fab fa-twitter",
    },
    {
      id: 3,
      link: "google-plus",
      iconClass: "fab fa-google-plus-g",
    },
    {
      id: 4,
      link: "vine",
      iconClass: "fab fa-vine",
    },
  ],
  footerList_1: [
    {
      id: 1,
      text: "Home",
      href: "/#",
    },
    {
      id: 4,
      text: "Screenshots",
      href: "/#screenshots",
    },
    {
      id: 3,
      text: "Download",
      href: "/#download",
    },
    {
      id: 2,
      text: "Features",
      href: "/#features",
    },
  ],
  footerList_2: [
    {
      id: 1,
      text: "How To",
      href: "/#work",
    },
    {
      id: 2,
      text: "FAQ",
      href: "/#faq",
    },
    {
      id: 3,
      text: "Smart Display Instruction",
      href: "/instruction",
    },
    {
      id: 4,
      text: "Android Builds",
      href: "/beta",
    },
    {
      id: 5,
      text: "Privacy Policy",
      href: "/privacy-policy",
    },
    {
      id: 6,
      text: "Terms & Conditions",
      href: "/terms-conditions",
    },
  ],
  footerList_3: [
    {
      id: 1,
      image: "/img/app-store-black.png",
      href: "/ios",
    },
    {
      id: 2,
      image: "/img/google-play-black.png",
      href: "/android",
    },
  ],
};

class FooterSection extends Component {
  state = {
    data: {},
    iconList: [],
    footerList_1: [],
    footerList_2: [],
    footerList_3: [],
  };
  componentDidMount() {
    this.setState({
      data: DATA,
      iconList: DATA.iconList,
      footerList_1: DATA.footerList_1,
      footerList_2: DATA.footerList_2,
      footerList_3: DATA.footerList_3,
    });
  }
  render() {
    return (
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="footer-top ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={this.state.data.image}
                        alt=""
                      />
                    </a>
                    <p className="mt-2 mb-3">{this.state.data.text}</p>
                    {/* Social Icons */}
                    {/* <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a key={`fi_${idx}`} className={item.link} href="/#">
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href={item.href}>{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href={item.href}>{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_3}
                    </h3>
                    {/* Store Buttons */}
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {this.state.footerList_3.map((item, idx) => {
                        return (
                          <a key={`flth_${idx}`} href={item.href}>
                            <img src={item.image} alt="" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights {new Date().getFullYear()} LinkUP All rights
                      reserved.
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right">
                      Developed By{" "}
                      <a href="https://linkup-get.app">Tapply Co., Ltd.</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;
