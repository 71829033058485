import React, { Component } from "react";

import HeaderLinkUP from "../components/HeaderSection/Header-linkup";
import HeroSection from "../components/HeroSection/Hero-linkup";
import FeatureSection from "../components/Features/Feature-linkup";
import Download from "../components/DownloadSection/Download-linkup";
import ScreenshotSection from "../components/ScreenshotSection/Screenshots-linkup";
import Work from "../components/WorkSection/Work-linkup";
import FaqSection from "../components/FaqSection/Faq-linkup";
// import ContactSection from "../components/ContactSection/Contact-linkup";
import FooterSection from "../components/FooterSection/Footer-linkup";

// NOTE: example http://react.theme-land.com/theme-two

class ThemeLinkUP extends Component {
  render() {
    return (
      <div className="homepage-2">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <HeaderLinkUP imageData={"/img/logo.png"} />
          <HeroSection />
          <ScreenshotSection />
          <Download />
          <FeatureSection />
          <Work />
          <FaqSection />
          {/* TODO: submit form data to spreadsheets: https://medium.com/@mheavers/storing-form-data-from-a-website-in-google-spreadsheets-using-javascript-react-a15aeb9143cb */}
          {/* TODO: software@dc-ac.com spreadsheets: https://docs.google.com/spreadsheets/d/1Gq6f6X0-neKMxnpWDAYSBObmNpRA57d8Y8xk2N7heeU/edit#gid=0 */}
          {/* <ContactSection /> */}
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default ThemeLinkUP;
