import React, { Component } from "react";

class HeaderLinkUP extends Component {
  render() {
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img
              className="navbar-brand-regular"
              src={this.props.imageData}
              alt="brand-logo"
              style={{ height: 50 }}
            />
            <img
              className="navbar-brand-sticky"
              src={this.props.imageData}
              alt="sticky brand-logo"
              style={{ height: 50 }}
            />
          </a>
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner">
            {/*  Mobile Menu Toggler */}
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="/#screenshots">
                    Screenshots
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="/#download">
                    Download
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link scroll" href="/#features">
                    Features
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Support
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="/#work">
                        How To
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/#faq">
                        FAQ
                      </a>
                    </li>
                    {/* <li>
                      <a className="dropdown-item" href="/#contact">
                        Feedback
                      </a>
                    </li> */}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderLinkUP;
